const url = window.location.hostname;

let setURL = "https://ecasenka.sk/wp-content/search-images";
let setAPI = "https://api.ecasenka.sk/frontapi";
let setBASE_URL = "https://www.ecasenka.sk";
let setLOCAL_URL = "https://www.ecasenka.sk";
let setNEW_API = "https://ecasenka-uat.goodrequest.dev/api/v1/legacy/branches";

if (
  url == "localhost" ||
  url == "www.ecasenka-test.sk" ||
  url == "ecasenka-test.sk" ||
  url == "www.ecasenka-dev.sk" ||
  url == "ecasenka-dev.sk"
) {
  console.warn("You are working with app search doctor in a test environment!");
  setURL = "https://" + url + "/wp-content/search-images";
  setAPI = "https://api.ecasenka-test.sk/frontapi";
  setLOCAL_URL = "https://" + url;
}

if(  url == "www.ecasenka-dev.sk" || url == "ecasenka-dev.sk"){
  setAPI = "https://api.ecasenka-dev.sk/frontapi";
}

if (url == "localhost") {
  setAPI = "https://api.ecasenka.sk/frontapi";
  setURL = "https://ecasenka-test.sk/wp-content/search-images";
  setLOCAL_URL = "https://ecasenka-test.sk";
}

const config = {
  URL: setURL,
  API: setAPI,
  BASE_URL: setBASE_URL,
  LOCAL_URL: setLOCAL_URL,
  ALLOW_OLD_API: true,
  ALLOW_NEW_API: true, // enable to allow new api
  API_NEW: setNEW_API
};

export default config;
