import axios from "axios";
import config from "../../config";

/*
Import module to component example:
-----------------------------------
import { SearchDoctor } from '../modules/SearchDoctorModule';

Use example in vue function:
----------------------------
            SearchDoctor.search(citylist).then((values) => {
            this.doctors = values;
            console.log(values)
            // this.$root.$emit("doctors", values);
            }); 
*/

class SearchDoctorClass {
  constructor() {}

  search(data, cities, specializations) {
    if (config.ALLOW_OLD_API == true || config.ALLOW_NEW_API == true) {
      let mergedData = [];

      let oldapi = {
        data: [],
        status: null,
        statusText: null,
        headers: {},
        config: {},
      };

      let newapi = {
        data: [],
        status: null,
        statusText: null,
        headers: {},
        config: {},
      };

      if (config.ALLOW_OLD_API == true) {
        oldapi = axios.post(config.API + "/search/", data);
      }

      if (config.ALLOW_NEW_API == true) {
        let searchtext = "";

        if (data.search != undefined) {
          searchtext = data.search + " " || "";
        }

        if (data.city_id != undefined) {
          let s_city = getCityById(cities, data.city_id);
          if (s_city != null && s_city != undefined) {
            searchtext = searchtext + s_city + " ";
          }
        }

        if (data.specialty_id != undefined) {
          let s_spec = getSpecializationById(
            specializations,
            data.specialty_id
          );
          if (s_spec != null && s_spec != undefined) {
            searchtext = searchtext + s_spec;
          }
        }

        //console.log('SEARCHTEXT:' + searchtext)
        if (searchtext != null && searchtext.toString() != "") {
          newapi = axios.get(config.API_NEW + "/", {
            params: { search: searchtext.toString() },
          });
        }
      }

      let promiseOld = Promise.all([oldapi])
        .then((responses) => {
          let data_from_old_api = responses[0].data;

          data_from_old_api.forEach(function(value) {
            value.url = sanitize_doctor_old_url(value.url);
          });

          return data_from_old_api;
        })
        .catch(() => {
          // At least one of the promises was rejected
          console.warn("OLD API ENDPOINT ERROR OCCURED");
          return [];
        });

      let promiseNew = Promise.all([newapi])
        .then((responses) => {
          let data_from_new_api = [];
          if (responses[0].data.branches != undefined) {
            data_from_new_api = responses[0].data.branches;
          }

          data_from_new_api.forEach(function(value) {
            value.url = sanitize_doctor_new_url(value.url);
          });

          return data_from_new_api;
        })
        .catch(() => {
          // At least one of the promises was rejected
          console.warn("NEW API ENDPOINT ERROR OCCURED");
          return [];
        });

      // Use Promise.all to wait for both promises to resolve
      mergedData = Promise.all([promiseOld, promiseNew])
        .then(([mergedDataOld, mergedDataNew]) => {
          // You can merge the data once both promises have resolved successfully
          return (mergedData = [...mergedDataOld, ...mergedDataNew]);
        })
        .catch(() => {
          // Handle any errors from the final Promise.all
        });

      /* Sort doctors by last name alphabetically */
      mergedData.then((response) => {
        mergedData = response.sort((a, b) => {
          let aname = a.drname.toLowerCase();
          aname = aname.replace("mudr", "");
          aname = aname.replace(".", "");
          aname = aname.replace(",", "");
          aname = aname.trim();
          let asurname = aname.split(" ");

          let bname = b.drname.toLowerCase();
          bname = bname.replace("mudr", "");
          bname = bname.replace(".", "");
          bname = bname.replace(",", "");
          bname = bname.trim();
          let bsurname = bname.split(" ");

          /* Convert the string to a normalized Unicode format  */
          let atestname = asurname[1];
          let btestname = bsurname[1];

          try {
            atestname = asurname[1]
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
            btestname = bsurname[1]
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
          } catch {
            atestname = asurname[1];
            btestname = bsurname[1];
          }

          if (atestname < btestname) return -1;
          if (atestname > btestname) return 1;
          return 0;
        });
      });

      return mergedData;
    }

    return new Promise((success) => {
      success([]);
    });
  }
}

/* Sanitize url from new API and add custom parameter to url */
function sanitize_doctor_new_url(oldurl) {
  let seturl =
    config.BASE_URL + "/ordinacia/doctor_id_" + oldurl.toString().trim();
  let url = new URL(seturl);
  let params = new URLSearchParams(url.search);
  url.search = params.toString();
  let newurl = url.href.toString();
  return newurl.replace(config.BASE_URL, config.LOCAL_URL);
}

/* Sanitize url from old API */
function sanitize_doctor_old_url(oldurl) {
  let url = new URL(oldurl);
  let newurl = url.href.toString();
  return newurl.replace(config.BASE_URL, config.LOCAL_URL);
}

function getCityById(citiesArray, id) {
  // Find the object with the matching ID
  let cityObject = citiesArray.find(function(item) {
    return item.id === id;
  });

  // Return the text value if the object is found
  if (cityObject) {
    return cityObject.text;
  }

  // Return null or a default value if the object is not found
  return null;
}

function getSpecializationById(specializationsArray, id) {
  // Find the object with the matching ID
  let specObject = specializationsArray.find(function(item) {
    return item.id === id;
  });

  // Return the text value if the object is found
  if (specObject) {
    return specObject.text;
  }

  // Return null or a default value if the object is not found
  return null;
}

let SearchDoctor = new SearchDoctorClass();

export { SearchDoctor };
