import Vue from "vue";
import App from "./App.vue";
import VueGeolocation from "vue-browser-geolocation";
import VueSession from "vue-session";

Vue.use(VueGeolocation);
Vue.use(VueSession);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#ecasenkaSearch");
